import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import '../assets/css/toolbarnav.css';

/**
 * Header navigator like mobile app navigator
 * @name HeaderNavigator
 * @version 1.0.0
 * @author siemah
 * @package dayenio.ml.gammerce
 * @param {object} props
 */
const onBack = () => {
  if(typeof window !== `undefined`) {
    window.history.back();
  }
}
export default function HeaderNavigator({ className = '', containerClassName = '', title, label, titleClassName = '', subtitle, backButton: BackButton, toolbar: ToolbarMenu }) {
  
  return (
    <>
    <nav className={`header-navigator__block ${className}`}>
      <div className={`header-navigator__container ${containerClassName}`}>
        <div className={`header-navigator__item header-navigator__left`}>
          <button className={`header-navigator__action-link ph-2`} onClick={onBack}>
            <span className={`header-navigator__icon-block`}>
              {
                BackButton
                  ? <BackButton />
                  : <FontAwesomeIcon className={`header-navigator__icon`} icon={faChevronLeft} />
              }
            </span>
          </button>
        </div>
        <div className={`header-navigator__item header-navigator__body`}>
          {
            (title || label) &&
            <h1 className={`header-navigator__title ${titleClassName}`}>{title || label}</h1>
          }
          {
            (subtitle) &&
            <h1>{subtitle}</h1>
          }
        </div>
        {
          ToolbarMenu && (
            <div className={`header-navigator__item header-navigator__right`}>
              <button className={`header-navigator__action-link toolbar-menu__toggle-button ph-2`}>
                <span className={`header-navigator__icon-block`}>
                  <FontAwesomeIcon className={`header-navigator__icon`} icon={faEllipsisV} />
                </span>
              </button>
              {ToolbarMenu}
            </div>
          )
        }
      </div>
    </nav>
    <div className={`fake-block`} />
    </>
  )
}
