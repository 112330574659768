import React from 'react'

/**
 * @param {String} price represent the price of product
 * @param {String} sale_price discount price
 * @param {String} language language of product (frensh or arabic)
 * @var {Function} getDiscount is function allow to compute a discount percent
 * @var {Function} currency define currency depend on language of product
 * @return {Function} price Component
 */
export default ({ regular_price, price, language }) => {

  /**
   * compute the discount off of price
   * @returns {number}
   */
  const getDiscount = () => {
    if(!regular_price || !regular_price.length ) return 0;
    else if( parseFloat(regular_price) > 0) {
      let difference = parseFloat(regular_price) - parseFloat(price);
      return Math.round(difference * 100 / parseFloat(regular_price));
    } else return 0;
  };

  /**
   * retreive a currency depend on product language
   * @returns {String}
   */
  const currency = () => {
    switch (language) {
      case "fr": return "DA";
      case "ar": return "دج";
      default: return "DA";
    }
  };

  const discount = getDiscount();

  return (
    <h4 className="product-ui__product-details__price-block">
      {
        discount !== 0?
          <>
            <div className="product-ui__product-details__price-block_left left">
              Prix: <span className="product-ui__product-details__price-block__price">
                <span itemProp='price'>{price}</span>&nbsp;<span itemProp='priceCurrency'>{currency()}</span>
                </span>&nbsp;
              <span className="product-ui__product-details__price-block__price-old line-through">
                {`${regular_price}${currency()}`}
              </span>
            </div>
            <div className="product-ui__product-details__price-block_right right">
              <span className="product-discount">Remise : {discount}%</span>
            </div>
          </> :
          <>
            Prix: <span className="product-ui__product-details__price-block__price">
              <span itemProp='price'>{price}</span>&nbsp;<span itemProp='priceCurrency'>{currency()}</span>
            </span>
          </>
      }
    </h4>
  )

}
