import React from 'react';

/**
 * @name ProductDetails
 * product details menu component
 */
export default (props) => {

  /**
   * @name onClick
   * @param {HTMLElement} target the element fired click event
   * add active ckass to the parent of the triggered of click event
   */
  const onClick = e => {
    // e.preventDefault();
    if( typeof window !== `undefined` ) {
      document
        .querySelectorAll('.product-details__menu-item')
        .forEach( li => li.className = li.className.replace('active', '') );
      e.target.parentNode.className += " active";
    }
  }

  return (
    <nav className='product-details__menu'>
      <ul className="product-details__menu-container p0 m0">
        <li className="left product-details__menu-item active">
          <a href="#description" onClick={onClick}>Description</a>
        </li>
        <li className="left product-details__menu-item">
          <a href="#features" onClick={onClick}>Caracteristique</a>
        </li>
        <li className="left product-details__menu-item">
          <a href="#rate" onClick={onClick}>Avis</a>
        </li>
      </ul>
    </nav>
  );
}
