import React,{Component} from 'react';

import ProductDetailsMenu from './details/ProductDetailsMenu';
import ProductDetailsDescription from './details/ProductDetailsDescription';
import ProductDetailsFeatures from './details/ProductDetailsFeatures';
import ProductDetailsRating from './details/ProductDetailsRating';
/**
 * Contain all details about current product
 */
export default class Class extends Component {

  render() {
    let { description, short_description, name, dimensions, sku, acf, stock_quantity } = this.props.product;
    // console.log(this.props.product);
    return (
      <div className="product-details-container">
        <ProductDetailsMenu />
        <ProductDetailsDescription description={description} />
        <ProductDetailsFeatures
          short_description={short_description}
          name={name}
          dimensions={dimensions}
          sku={sku}
          acf={acf}
          stock_quantity={stock_quantity}
         />
        {/* later after create rate process and fix woocommerce status response 401 */}
        <ProductDetailsRating productId={this.props.productId} productName={name} reviews={this.props.reviews} />
      </div>
    );
  }

}
