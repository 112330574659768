import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

import ProductImages from './widgets/ProductImages';
import ProductName from './widgets/ProductName';
import Price from './widgets/Price';
import Quantity from "./widgets/Quantity";

import LikeIcon from "../../assets/images/icons/like.svg";
import LikeIconActive from "../../assets/images/icons/like-active.svg";


import { addToFavoriteList, removeFromFavoriteList, checkIfProductInFavoriteList } from "../../utils/actions/product";

/**
 * Product form of product without attributes like colors, weight ...
 */
export default class SimpleProductForm extends Component {

  state = {
    isLiked: false,
  }

    /**
     * add/remove to/from favorite list (likedProductsList)
     */
    onClick = (target) => {
      let {
        slug, name, price, regular_price, average_rating, rating_count, acf, images
      } = this.props.product;
      if ( !checkIfProductInFavoriteList(slug) ) {
        addToFavoriteList({
          slug,
          name: name,
          image: images[0].src,
          price, regular_price,
          average_rating,
          rating_count,
          acf
        });
        this.refreshState(true);
      } else {
        removeFromFavoriteList(slug);
        this.refreshState(false);
      }
    }

    /**
     * check if current product is added to favorite list and update state
     */
     refreshState = isLiked => {
       this.setState({
         isLiked
       })
     }


   /**
    * @name onAddToBasket
    * triggered when costumer click a button 'ajouter au panier'
    * and then the current product added to basket
    */
    onAddToBasket = e => {
      e.preventDefault();
      this.props.addCurrentProductToBasket();
      this.showToast(e.target);
    }

    /**
     * @name showToast
     * this showed a toast with custom message
     * inform costumer they had added a product
     * to basket
     * @param {HTMLElement} target the current
     * HTMLElement who triggered this event
     */
     showToast = target => {
       if( typeof window !== `undefined` ) {
         let $toast = target.querySelector('.toast');
         if( !~$toast.className.indexOf('fadein') ) {
           $toast.className += ' fadein';
           setTimeout(() => {
             $toast.className = $toast.className.replace(' fadein', '')
           }, 10000);
         }
       }
     }

    componentDidMount() {
      this.refreshState(checkIfProductInFavoriteList(this.props.product.slug));
    }

  render() {
    let { id, price, regular_price, acf, name, images, short_description } = this.props.product;
    return (
      <form itemScope itemType="http://schema.org/Product" className={`product-ui product-${id} row`} onSubmit={this.props.onSubmit}>

        <div className="col s12 m5 product-ui__images" >
          <ProductImages images={images} />
        </div>

        <div className="product-ui__product-details col s12 m7">

          <ProductName name={name} brand={acf.brand} />
          <Price
            regular_price={regular_price}
            price={price}
            language={acf.language}
          />
          <div className="product-ui__short-details">
            <h4 className="short-details__title" itemProp='description'>Details de produit : </h4>
            {ReactHtmlParser(short_description)}
          </div>
          <Quantity
            quantity={this.props.quantity}
            onChange={this.props.onChange}
            setQuantity={this.props.setQuantity}
          />
          <div className="product-ui__form-actions">
            <button className="product-ui__button product-ui__button_red product-ui__button-pulse product-button__buy" onClick={this.props.onSubmit} type='button'>
              <span>J'achete</span>{` `}
              <span dir='rtl'>اشتري الآن</span>
            </button>
            <button
              className="product-ui__button product-ui__button_panel"
              type='button'
              onClick={this.onAddToBasket}>
              <div className="toast-container toast">Vous avez ajouter un produit au panier</div>
              Ajouter au panier
            </button>
            <a href='tel:0557841237' title='Appeler pour acheter' className="product-ui__button product-ui__button-tel mr0">Appeler</a>
            <button
              className={`card__action__button right p0 ${this.state.isLiked? 'card__action__button_active' : ''}`}
              onClick={ (e) => {
                e.preventDefault();
                this.onClick(e.target)
              } } type="button">
              <img height={25} src={this.state.isLiked? LikeIconActive : LikeIcon} alt='like heart' />
            </button>
          </div>

        </div>

      </form>
    )
  }

}
