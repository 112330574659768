import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SimpleProductForm from '../components/product/SimpleProductForm';
import VariableProductForm from '../components/product/VariableProductForm';
import ProductDetails from "../components/product/ProductDetails";
import { Row, Col } from "../components/layout/grid";
import ToolbarNav from '../components/ToolbarNav';

import { capitalize, getId } from "../utils/tools";
import { addToBasket } from "../utils/actions/product";

import "../assets/css/product.css";
import DeliveryMan from '../assets/images/icons/delivery-man.svg';
import PayOnDelivery from '../assets/images/icons/pay.svg';
import Thinkings from '../assets/images/icons/thinking.svg';
import H24Support from '../assets/images/icons/24-hours-support.svg';
import { faShoppingBag, faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolbarMenu from "../components/ToolbarMenu";
import DefaultImg from '../assets/images/logo.png';

export default class ProdcutPage extends React.Component {

  state = {
    line_items: [
      {
        product_id: 22,
        quantity: 1,
        meta_data: [],
      }
    ]
  }

  /**
   * initialize a state with some default value
   */
  initializeState = () => {
    const { wcProducts: product } = this.props.data;
    this.setState((prevState) => {
      return {
        line_items: [{
          product_id: getId(product._links),
          quantity: 1,
          meta_data: [],
        }]
      }
    })
  }

  /**
   * change a state value depend on which property changed by user
   * @param e HTMLElementEvent
   */
  onChange = ({ target }) => {
    // let { line_items } = this.state;
    this.setState({
      line_items: [{
        ...this.state.line_items[0],
        [target.name]: target.value,
      }]
    });
  }

  /**
   * @name addCurrentProductToBasket
   * add the current product to basket
   */
  addCurrentProductToBasket = () => {
    addToBasket({
      ...this.state.line_items[0],
      name: this.props.data.wcProducts.name,
      slug: this.props.data.wcProducts.slug,
      price: this.props.data.wcProducts.price,
      regular_price: this.props.data.wcProducts.regular_price,
      image: this.props.data.wcProducts.images[0].src
    });
  }


  // add to cart event for facebook pixel
  addToCart = () => {
    if (typeof window !== `undefined`) {
      window.fbq && window.fbq('track', 'AddToCart',
        {
          value: this.props.data.wcProducts.price,
          currency: 'DZD',
          content_type: 'product', // required property
          content_ids: `${this.props.data.wcProducts.wordpress_id}` // required property, if not using 'contents' property
        }
      );
    }
  }

  /**
   * add to panel this current product
   * and redirect costumer ton pane page
   */
  onSubmit = async e => {
    e.preventDefault();
    this.addCurrentProductToBasket();
    this.addToCart();
    this.props.navigate('/panier/', {
      state: { step: 2 },
    });
  }

  /**
   * increment or decrement a quantity of product
   * @param {HTMLElement} target is html dom element
   * fired an event then add or decrement a quantity
   */
  setQuantity = ({ target }) => {
    let { quantity } = this.state.line_items[0];
    let newQuantity = target.getAttribute('data-effect') === '+' ?
      (quantity + 1) :
      (quantity - 1);
    newQuantity = newQuantity < 1 ? 1 : newQuantity;
    this.setState({
      line_items: [{
        ...this.state.line_items[0],
        quantity: newQuantity,
      }]
    })
  }

  /**
   * change meta data of this product depend on attributes
   * @param {HTMLElement} target is html dom element
   * fired an event then change or add meta data of line_items
   */
  setProductMetaDate = ({ target }) => {

    let { meta_data } = this.state.line_items[0];
    let isExist = false, existantIndex = null;
    let metaDataValue = null;
    meta_data.filter((meta, i) => {
      if (meta.key === target.name) {
        existantIndex = i;
        isExist = true;
        metaDataValue = meta_data[i];
        return meta.key === target.name;
      }
      return false;
    });

    if (isExist) {

      metaDataValue.value = target.value;
      this.setState(prevState => ({
        line_items: [{
          ...prevState.line_items[0],
          meta_data: [
            ...meta_data.slice(0, existantIndex),
            ...meta_data.slice(existantIndex + 1),
            metaDataValue,
          ],
        }]
      }));

    }
    else {
      metaDataValue = {
        key: target.name,
        label: target.name.toUpperCase(),
        value: target.value,
      }
      this.setState(prevState => {
        return {
          line_items: [{
            ...prevState.line_items[0],
            meta_data: [...prevState.line_items[0].meta_data, metaDataValue],
          }]
        }
      });
    }

  }

  /**
   * @component lifecycle method
   */
  componentDidMount() {
    this.initializeState();
  }

  render() {
    if (!('data' in this.props) || !('wcProducts' in this.props.data)) this.props.navigate('/404/')
    const {
      wcProducts: product,
      allWcProductsReviews: reviews,
      allWcProductsCategories: categories
    } = this.props.data;
    let categoriesKeys = categories.edges.map(({ node: category }) => category.name);
    return (
      <Layout
        product={product}
        navigate={this.props.navigate}
        categories={categories}
        location={this.props.location}
      >
        <SEO
          title={`${capitalize(product.name)}`}
          keywords={[...product.slug.split('-'), product.name, `aalladine`, `ecommerce boutique`, ...categoriesKeys]}
          description={product.description.replace(/<[^>]*>/g, "")}
          link={`${'produit/' + product.slug}`}
          image={product.images && product.images[0] ? product.images[0].src : DefaultImg}
          price={product.price || product.regular_price}
        />

        <div className="procut-page-container procut-page ">
          <ToolbarNav
            title={product.name}
            onBack={null}
            containerClassName={`container`}
            toolbar={<ToolbarMenu items={[
              { icon: <FontAwesomeIcon icon={faHome} />, link: '/', label: 'Accueil' },
              { icon: <FontAwesomeIcon icon={faShoppingBag} />, link: '/panier', label: 'Panier' },
              { icon: <FontAwesomeIcon icon={faSearch} />, link: '/search', label: 'Recherche' },
            ]} />}
          />

          {
            product.attributes.length > 0 ?
              <VariableProductForm
                product={product}
                quantity={this.state.line_items[0].quantity}
                setQuantity={this.setQuantity}
                onChange={this.onChange}
                setProductMetaDate={this.setProductMetaDate}
                onSubmit={this.onSubmit}
                addCurrentProductToBasket={this.addCurrentProductToBasket}
              /> :
              <SimpleProductForm
                product={product}
                quantity={this.state.line_items[0].quantity}
                setQuantity={this.setQuantity}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                addCurrentProductToBasket={this.addCurrentProductToBasket}
              />
          }


          <Row className="product-page-container__our-services mb0">
            <Col s='3' m='6' l='3' className="product-page-container__service-item">
              <div className="product-page-container__service-item-wrapper">
                <div className="product-page-container__service-image loading-background js-lazy-background" data-background={DeliveryMan} />
                <div className="product-page-container__service-item-container">
                  <h2 className="product-page-container__service-item-title">Votre commande arrive en 7 jours</h2>
                  <p className="product-page-container__service-item-description">Recevez votre produits</p>
                </div>
              </div>
            </Col>
            <Col s='3' m='6' l='3' className="product-page-container__service-item">
              <div className="product-page-container__service-item-wrapper">
                <div className="product-page-container__service-image loading-background js-lazy-background" data-background={PayOnDelivery} />
                <div className="product-page-container__service-item-container">
                  <h2 className="product-page-container__service-item-title">Payez à la livraison</h2>
                  <p className="product-page-container__service-item-description">En cash à votre porte</p>
                </div>
              </div>
            </Col>
            <Col s='3' m='6' l='3' className="product-page-container__service-item">
              <div className="product-page-container__service-item-wrapper">
                <div className="product-page-container__service-image loading-background js-lazy-background" data-background={Thinkings} />
                <div className="product-page-container__service-item-container">
                  <h2 className="product-page-container__service-item-title">Creativité et productivité</h2>
                  <p className="product-page-container__service-item-description">Nous nous soucions de vous</p>
                </div>
              </div>
            </Col>
            <Col s='3' m='6' l='3' className="product-page-container__service-item">
              <div className="product-page-container__service-item-wrapper">
                <div className="product-page-container__service-image loading-background js-lazy-background" data-background={H24Support} />
                <div className="product-page-container__service-item-container">
                  <h2 className="product-page-container__service-item-title">Disponibilité 7j/24h</h2>
                  <p className="product-page-container__service-item-description">Commander ou nous contactez en tous moments</p>
                </div>
              </div>
            </Col>
          </Row>


          <ProductDetails product={product} reviews={reviews} productId={getId(product._links)} />

        </div>

      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!, $productId: Int!) {
    allWcProductsCategories (
      filter : { slug : {nin: ["uncategorized", "non-classe"]} },
      sort: { fields: [count], order: DESC }
     ) {
      edges {
       node {
         id
         
         name
         slug
         display
         image {
           src
           name
         }
         _links{
           self{
             href
           }
         }
         count
       }
      }
    }
    wcProducts(id: { eq: $id }) {
      id
      wordpress_id
      name
      slug
      type
      price
      regular_price
      purchase_note
      description
      short_description
      backorders_allowed
      stock_quantity
      sku
      shipping_required
      dimensions {
          length
          width
          height
        }
      weight
      acf {
        language
        brand
      }
      attributes {
        id
        name
        options
      }
      images {
        id
        src
        date_created
        name
        alt
      }
      _links {
        self {
          href
        }
      }
    }
    allWcProductsReviews(filter: { product_id: { eq: $productId } }) {
      edges {
       node {
         id
         product_id
         rating
         reviewer_email
         reviewer
         review
         date_created_gmt
       }
      }
    }
  }
`
