import React from "react";

export default ({ isLighten, height, width }) => (
  <svg
    version="1.1"
    x="0px" y="0px"
    viewBox="0 0 53.867 53.867"
    style={{enableBackground:'new 0 0 53.867 53.867', height, width}}
   >
    <polygon style={{fill: isLighten? '#ff8a00' : '#EFEEEA'}} points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798
    10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "/>
  </svg>

)
