import React from 'react';

import Star from '../widgets/Star';
import ProgressBar from '../widgets/ProgressBar';

import { rateProduct } from '../../../utils/actions/product';

/**
 * @name ProductDetailsRating
 * Rating component by sending a ajax request to server
 * and show all previous reviews(old)
 */
export default class ProductDetailsRating extends React.Component {

  state = {
    rating: 5,
    reviewer: '',
    reviewer_email: '',
    product_id: null,
    review: 'Produit de qualité!',
  }

  /**
   * add rating of current product
   * this method triggered when submit event
   * fired by form or when click a submit button
   * @param {DOMEvent} e is dom event od submitting form
   */
  onSubmit = async e => {
    e.preventDefault();
    // rating action and
    try {
      let res = await rateProduct(this.state);
      console.log(res);
      alert('Your just add a rete to '+ this.props.productName);
    } catch (e) {
      alert('Error ', e.message);
    }
  }

  /**
   * Change state depend on name of field input
   * @param {HTMLElement} target is the field who fired a change event
   */
  onChange = ({ target }) => this.setState({[target.name]: target.value});

  /**
   * classify all product rating depend on rating from 1 to 5 and in
   * the same time compute the average rating and prencent of this product
   * @param {Array||null} reviews contain several object of reviews details or null
   * @return {Object} contain averageRating {Float} it's clair and reviews {Array}
   * classify rating from 1 to 5 redandancy
   */
  classifyRating = reviews => {
    let newReviews = [
      { totalCount: 0, percent: 0, rate: 1, id: "0"},
      { totalCount: 0, percent: 0, rate: 2, id: "1"},
      { totalCount: 0, percent: 0, rate: 3, id: "2"},
      { totalCount: 0, percent: 0, rate: 4, id: "3"},
      { totalCount: 0, percent: 0, rate: 5, id: "4"},
    ];
    let averageRating = 0;
    if( reviews !== null ) {
      reviews.forEach( ({ node }, i) => {
        averageRating += parseInt(node.rating, 10);
        newReviews[parseInt(node.rating, 10)-1].id = `${node.id}${node.rating}`;
        newReviews[parseInt(node.rating, 10)-1].totalCount = newReviews[ parseInt(node.rating, 10)-1 ].totalCount + 1;
        newReviews[parseInt(node.rating, 10)-1].percent    = parseFloat(newReviews[ parseInt(node.rating, 10)-1 ].totalCount/reviews.length)*100;
      });
    }
    return {
      averageRating: averageRating ? averageRating / reviews.length : 0,
      reviews: newReviews
    };
  }

  /**
   * initialize a product_id of state to thee current product page
   */
  componentDidMount() {
    this.setState({ product_id: this.props.productId });
  }

  render() {
    let { averageRating, reviews } = this.classifyRating(this.props.reviews? this.props.reviews.edges : []);
    // console.log(averageRating, reviews);
    return(
      <div className='product-details-rate' id="rate">
        <form method="POST" action='#' className='row' onSubmit={this.onSubmit}>
          <div className="product-details-rate__average-rating center col s12 m4">
            <Star isLighten={true} height={150} className='product-details-rate__star' />
            <span className="product-details-rate__rate-score">{ averageRating.toString().substr(0, 3) }</span>
          </div>
          <div className="product-old-reviews-list-rating col s12 m4">
          {
            reviews.map( review => (
              <div className="progress-bar-container row mb0" key={`progress-${review.id}`} >
                <span className="col s4">{review.rate} star </span>
                <ProgressBar width={`${review.percent}%`} />
              </div>
            ))
          }
          </div>
          <div className="product-rating col s12 m4 row">
            <div className="rating-action col s12">
              <label htmlFor="rating1">
                <input type='radio' className="product-rating_hidden" name="rating" value='1' onChange={this.onChange} id="rating1"/>
                <Star height='25px' width='25px' isLighten/>
              </label>
              <label htmlFor="rating2">
                <input type='radio' className="product-rating_hidden" name="rating" value='2' onChange={this.onChange} id="rating2"/>
                <Star height='25px' width='25px' isLighten/>
              </label>
              <label htmlFor="rating3">
                <input type='radio' className="product-rating_hidden" name="rating" value='3' onChange={this.onChange} id="rating3"/>
                <Star height='25px' width='25px' isLighten/>
              </label>
              <label htmlFor="rating4">
                <input type='radio' className="product-rating_hidden" name="rating" value='4' onChange={this.onChange} id="rating4"/>
                <Star height='25px' width='25px' isLighten/>
              </label>
              <label htmlFor="rating5">
                <input type='radio' className="product-rating_hidden" name="rating" value='5' onChange={this.onChange} id="rating5" checked/>
                <Star height='25px' width='25px' isLighten/>
              </label>
            </div>
            <div className={'col s12'}>
              <label htmlFor="reviewer"></label>
              <textarea
              className='product-rating__filed product-rating__filed-border-radius_1-1-0-0'
              type="text" id='reviewer'
              placeholder='Votre avis' name='review'
              onChange={this.onChange}></textarea>
            </div>
            <div className={'col s12'}>
              <label htmlFor="reviewer"></label>
              <input
              className='product-rating__filed product-rating__filed-border_0-1-0-1'
              type="text" id='reviewer'
              placeholder='Nom et Prenom' name='reviewer'
              onChange={this.onChange}/>
            </div>
            <div className={'col s12'}>
              <label htmlFor="reviewer_email"></label>
              <input
              className='product-rating__filed '
              type="text" id='reviewer_email'
              placeholder='Adresse Email' name='reviewer_email'
              onChange={this.onChange}/>
            </div>
            <div className="col s12">
              <button className="product-rating__button" type='submit'>Ajouter un avis</button>
            </div>

          </div>
        </form>
      </div>
    );
  }
}
