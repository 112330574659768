import React from 'react';
/**
 * Component of attributes
 * @param {Array} attributes list of object contain all product attrinutes
 * @param {Function} onChange callback fired when any attribute choosed
 * by user that allow to change a parent state meta_data
 */
export default ({ attributes, onChange }) => {

  return (
    <div className='product-variations-container'>
      {
        attributes.map(({ id, name, options }, index) => (
          <div className={`product-vartiaion-container__item`} key={name}>
            <div className="left">
              <strong className="product-attribute capitalize color-grey">{name}</strong> :
            </div>
            <div className='right'>
              {
                options.map( (option, key) => (
                  <React.Fragment key={key}>
                   <input
                     name={name}
                     id={`product-variation-${id}-${option}`}
                     value={option}
                     type="radio"
                     className='product-variation_radio product-variation_hidden'
                     onChange={onChange}
                   />
                    <label
                      htmlFor={`product-variation-${id}-${option}`}
                      className={`product-variation-label_wrapper product-variation-${id}-${option}`}
                      key={option}
                     >
                      <strong>{option}</strong>
                    </label>
                  </React.Fragment>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}
