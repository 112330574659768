import React from 'react';
import { Link } from 'gatsby';

import '../assets/css/toolbar-menu.css';

/**
 * Render a link with possibility of embeding an icon iside link
 * @name MenuItem
 * @author siemah
 * @version 1.0.0
 * @package dayenio.ml.gammerce
 * @param {object} props component props config
 */
export const MenuItem = ({ link, label, icon: Icon, className=''}) => {
  return (
    <li className={`toolbar-menu__item ${className}`}>
      <Link to={`${link}`} className={`toolbar-menu__link`}>
        { 
          Icon && 
          <i className={`toolbar-menu__icon`}>
            {Icon}
          </i>
        }
        {label}
      </Link>
    </li>
  )
};
/**
 * toolbar manu like mobile apps menu
 * @name ToolbarMenu
 * @author siemah
 * @version 1.0.0
 * @package dayenio.ml.gammerce
 * @param {object} props component props config
 */
export default function ToolbarMenu({ items=[], }) {
  return (
    <nav className={`toolbar-menu__block`}>
      <ul className={`toolbar-menu__container`}>
        {
          items.map(
            ({link, label, ...rest}) => 
              <MenuItem key={link} label={label} link={link} {...rest} />
          )
        }
      </ul>
    </nav>
  )
}
