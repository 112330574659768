import React from 'react';

const ProductName = props => (
  <div className="product-ui__product-details__title_container capitalize">
    <h1 className='product-ui__product-details__title__name' itemProp="name">{props.name}</h1>
    <h2 className="product-ui__product-details__title__brand">Par <span itemProp="brand">{props.brand}</span></h2>
  </div>
);

export default ProductName;
