import React from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
  Image,
  ImageWithZoom,
} from "pure-react-carousel";

import backIcon from '../../../assets/images/icons/back.svg';
import nextIcon from '../../../assets/images/icons/next.svg';

import "pure-react-carousel/dist/react-carousel.es.css";

/**
 * Display a images of product inside slider
 * using pur-react-carousel plugin
 * @param {Array} images list of product images
 * @return {React.Component} slider images
 */
export default ({ images, slug }) => (
  <div >
    <CarouselProvider
      totalSlides={images.length}
      className="product-images__block"
     >
      <Slider className='product-images__block__slider'>
        {
          images.map( ( { id, src, alt, name }, i ) => (
              <Slide
                key={i}
                index={i}
                className="product-images__block__slide center"
              >
                <ImageWithZoom
                  className="product-images__block__slide__image-container center"
                  src={`https://images.weserv.nl/?url=${src}&w=400&fit=contain&bg=white&maxage=90d&we`}
                  alt={alt || name}
                />
              </Slide>
            )
          )
        }
      </Slider>
      <div className='product-images-images__block__dots'>
        {
          images.map(
            ( { id, src, alt, name }, i ) => (
              <Dot
                className='product-images-images__block__dots__item center p0'
                slide={i}
                key={i}
                aria-expanded="false"
                aria-pressed="mixed"
                aria-label={`burger-button`}
               >
                  <Image
                    hasMasterSpinner={true}
                    data-background={`https://images.weserv.nl/?url=${src}&w=400&fit=contain&bg=white&maxage=90d&we`}
                    tag='div'
                    className='product-images-images__block__dots__item-image loading-background js-lazy-background mb0'
                    alt={alt || name}
                  />
              </Dot>
            )
          )
        }
      </div>
      <ButtonBack className='product-images__button_direction product-images__button_direction_left'>
        <Image src={backIcon} />
      </ButtonBack>
      <ButtonNext className='product-images__button_direction product-images__button_direction_right'>
        <Image src={nextIcon} />
      </ButtonNext>

    </CarouselProvider>
  </div>
);
