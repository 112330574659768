import React from 'react';
import ReactHtmlParser from 'react-html-parser'

export default ({ short_description, name, acf, sku, dimensions, stock_quantity }) => (
  <div className='product-details-features' id="features">
    <h3 className="product-details-title mb0">Details de produit</h3>
    <div>{ReactHtmlParser(short_description)}</div>
    <h3 className="product-details-title mb0">
      {`CARACTÉRISTIQUES DE ${name}`}
    </h3>
    <table className='product-details-features-list'>
    <tbody>
      <tr>
        <td>Brand : </td>
        <td className="capitalize">{acf.brand}</td>
      </tr>
      <tr>
        <td>SKU : </td>
        <td >{sku}</td>
      </tr>
      <tr>
        <td>Qauntite en Stock : </td>
        <td>{stock_quantity} unité</td>
      </tr>
      <tr>
        <td>Longuer : </td>
        <td>{dimensions.width} cm</td>
      </tr>
      <tr>
        <td>Hauteur : </td>
        <td>{dimensions.height} cm</td>
      </tr>
      <tr>
        <td>Epessuer : </td>
        <td>{dimensions.length} cm</td>
      </tr>
      </tbody>
    </table>
  </div>

)
