import React from 'react';

/**
 * Product Quantity section
 * @param {Function} onChange fired when onchange event triggered in input field
 * @param {Function} setQuantity called when user click button to change quantity
 * @param quantity the default quantity passed from ParentComponent state
 * @returns {Component} of product quantity
 */
export default ({ onChange, setQuantity, quantity }) => {

  return (
    <div className="product-quantity-container">
      <div className="product-quantity-container_left">
        <label htmlFor="quantity" className='product-container__label'>Qantité : </label>
        <button
          className='product-quantity-container__button'
          type="button"
          onClick={setQuantity}
          data-effect="-" disabled={quantity <=1 ? true : false}>-</button>
        <input
          type="number"
          name="quantity"
          id='quantity'
          value={quantity}
          className="product-quantity-container__input-field"
          onChange={onChange}
        />
        <button
          className='product-quantity-container__button'
          type="button"
          onClick={setQuantity}
          data-effect="+">+</button>
      </div>
    </div>
  );

};
